import { _ } from "vue-underscore";

export default {
    data: () => ({
        content: '',
        backUrl: '/billing-states',
        billingState: {
            id: 0,
            country_code: "",
            state_name: "",
            state_code: "",

        },
        billingCountryList: []
    }),

    methods: {
        validateBillingState() {
            this.billingState.id > 0 ? this.update() : this.add();
        },
        update() {
            let _vm = this;
            // Send a POST request to update the billing state
            this.axios
                .post("/billing-states/" + this.billingState.id, {
                    ...this.billingState,
                    _method: "PATCH"
                })
                .then(function (response) {
                    // Update the billing state data
                    _vm.billingState = response.data.data;
                    // Redirect to the specified URL
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () {
                    // Handle any errors that occurred during the request
                });
        },

        add() {
            let _vm = this;
            // Send a POST request to "/billing-states" endpoint with the billingState data
            this.axios
                .post("/billing-states", _vm.billingState)
                .then(function (response) {
                    // If the request is successful, redirect to the specified backUrl
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () {
                    // If there is an error, do nothing
                });
        },

        getDetail() {
            // Assign 'this' to a local variable '_vm' to access it inside the 'then' function
            let _vm = this;
            // Make a GET request to retrieve billing state data
            this.axios
                .get("/billing-states/" + this.$route.params.id)
                .then(function (response) {
                    // Assign the retrieved data to 'billingState'
                    _vm.billingState = response.data.data;
                })
                .catch(function () {
                    // Handle any errors in the request
                });
        },
        // Retrieves the list of billing countries
        getBillingCountry() {
            const _vm = this
            this.axios
                .get('/billing-countries-list', _vm.billingCountryList)
                .then(function (response) {
                    _vm.billingCountryList = response.data.data
                })
                .catch(function () { })
        },
    },
    mounted() {
        if (this.$route.params.id > 0) {
            this.getDetail();
        }
        this.getBillingCountry();
    }
};
